import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as orderActions from '../../actions/order';
import * as pageActions from '../../actions/page';
import gglocationTypeEnum from '../../enums/gglocationTypeEnum';
import statusEnum from '../../enums/statusEnum';
import * as selectors from '../../sagas/selectors';

import deliveryInfoShape from '../../shapes/deliveryInfoShape';
import gglocationShape from '../../shapes/gglocationShape';
import orderItemShape from '../../shapes/orderItemShape';

import CartItem from '../../containers/Cart/CartItem';
import PickupTime from '../../containers/Cart/PickupTime';
import SelectedReward from '../../containers/SelectedReward';
import TopBar from '../../containers/TopBar/TopBarContainer';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button';
import CarbonOffset from '../CarbonOffset';
import DiningChoiceChanger from '../DiningChoiceChanger';
import DiningChoiceModal from '../DiningChoiceModal';
import InputAlertText from '../InputAlertText';
import LinearIcon from '../LinearIcon';
import LoyaltyFab from '../LoyaltyFab';
import Modal from '../Modal';
import SectionSeparator from '../SectionSeparator';
import CartButton from './CartButton';
import CartMinOrderBanner from './CartMinOrderBanner';
import CartPaymentInfo from './CartPaymentInfo';
import CartPriceLine from './CartPriceLine';
import PromoCodeInput from './PromoCodeInput';
import SelectPaymentMethod from './SelectPaymentMethod';
import TakeOutWithBagOption from './TakeOutWithBagOption';

import iconLocale from '../../images/icon-locale-black.svg';
import iconStation from '../../images/icon-station.svg';
import iconStore from '../../images/icon-store.svg';

import './Cart.css';

function Cart(props) {
  const {
    gglocation,
    orderItems,
    show,
    noStoreError,
    errorMessage,
    errorInterpretation,
    quantity,
    capacity,
    loggedIn,
    deliveryInfo,
    isDeliveryOrder,
    onCartHide,
    onClearCartClick,
    onErrorResolveClick,
  } = props;

  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const isMobile = width < 767;
  const isStationOrder = gglocation?.gglocationType === gglocationTypeEnum.PARTNER;

  const atCapacity = capacity && quantity > capacity;

  const currentLandingPageSlug = useSelector(selectors.getCurrentLandingPageSlug);
  const isDiningChoiceModalOpen = useSelector(selectors.getIsDiningChoiceModalOpen);
  const { totalTax, totalPrice } = useSelector(selectors.getOrderPricing);
  const { orderStatus } = useSelector(selectors.getOrder);
  const selectedUserRewardUuid = useSelector(selectors.getSelectedUserRewardUuid);

  const isOrderingDisabled = useMemo(
    () =>
      orderItems.length < 1 || [statusEnum.AWAITING, statusEnum.REQUESTED].includes(orderStatus),
    [orderItems, orderStatus],
  );

  const handleCloseDiningChoiceModal = () => {
    dispatch(pageActions.closeDiningChoiceModal());
  };

  useEffect(() => {
    /* Reset the gglocation if the landing page changes */
    if (currentLandingPageSlug && !gglocation) {
      dispatch(orderActions.resetGglocation());
      dispatch(pageActions.openDiningChoiceModal());
    }
  }, [currentLandingPageSlug, dispatch, gglocation]);

  const icon = isStationOrder ? iconStation : iconStore;

  return (
    <>
      {show && (
        <DiningChoiceModal
          isModalOpen={show && isDiningChoiceModalOpen}
          hideModal={handleCloseDiningChoiceModal}
        />
      )}
      <Modal
        backdrop={false}
        className={classnames('Cart', { delivery: isDeliveryOrder })}
        show={show && !isDiningChoiceModalOpen}
        onHide={onCartHide}
      >
        <TopBar isOverlay onExternalBackClick={onCartHide} />
        <Modal.Body className="kale iconBackground">
          <div className="CartModalBody">
            <div className="CartBody">
              {!isMobile && (
                <div className="CartBodyLeft">
                  <div className="CartBodyLeftTitle hidden-xs">
                    <Typography variant="h2" color="secondary.dark">
                      Your Order
                    </Typography>
                    {orderItems.length > 0 && (
                      <Button className="btn-secondary ClearCartButton" onClick={onClearCartClick}>
                        Clear cart
                      </Button>
                    )}
                  </div>
                  <div className="CartItemList">
                    {orderItems.map((orderItem) => (
                      <CartItem key={orderItem.id} orderItem={orderItem} />
                    ))}
                    {orderItems.length < 1 && !selectedUserRewardUuid && (
                      <div className="EmptyCartContainer">
                        <Typography variant="subtitle2">
                          You haven&apos;t added anything to your cart.
                        </Typography>
                        <Button className="btn-primary" onClick={onCartHide}>
                          Proceed to order
                        </Button>
                      </div>
                    )}
                    <SelectedReward fromCart />
                    {isDeliveryOrder && <CartMinOrderBanner />}
                    {!isDeliveryOrder && !isStationOrder && <TakeOutWithBagOption />}
                  </div>
                </div>
              )}
              <div className="CartCheckout">
                <div className="CartLocationInfo">
                  <div className="CartLocationName">
                    <Typography
                      variant="h2"
                      color="secondary.light"
                      fontWeight="900"
                      sx={{ fontFamily: 'GT Ultra Standard Ultra' }}
                    >
                      {isStationOrder && 'STATION '}
                      {isDeliveryOrder ? 'DELIVERY' : 'PICKUP'}
                    </Typography>
                    <DiningChoiceChanger />
                  </div>
                  <Typography variant="subtitle1" color="grey.main" className="CartLocationDetails">
                    <img src={isDeliveryOrder ? iconLocale : icon} alt="store" />
                    {isDeliveryOrder ? deliveryInfo?.deliveryAddressLabel : gglocation?.name ?? ''}
                  </Typography>
                </div>
                {atCapacity && (
                  <div className="alert-text">
                    <p>
                      <LinearIcon name="cart" />
                      Sorry, we can only accept orders of up to {capacity} items at the selected
                      time at {gglocation.name}.
                    </p>
                  </div>
                )}
                <PickupTime noStoreError={noStoreError} />
                {isMobile && (
                  <>
                    <SectionSeparator>YOUR ORDER</SectionSeparator>
                    <div className="CartItemList">
                      {orderItems.map((orderItem) => (
                        <CartItem key={orderItem.id} orderItem={orderItem} />
                      ))}
                      {orderItems.length < 1 && !selectedUserRewardUuid && (
                        <div className="EmptyCartContainer">
                          <Typography variant="subtitle2">
                            You haven&apos;t added anything to your cart.
                          </Typography>
                          <Button className="btn-primary" onClick={onCartHide}>
                            Proceed to order
                          </Button>
                        </div>
                      )}
                      <SelectedReward fromCart />
                    </div>
                    <CartMinOrderBanner />
                    {!isDeliveryOrder && !isStationOrder && <TakeOutWithBagOption />}
                  </>
                )}
                {loggedIn && (
                  <>
                    <SectionSeparator typographyVariant="h5">PAYMENT</SectionSeparator>
                    <SelectPaymentMethod />
                  </>
                )}
                <PromoCodeInput />
                <CarbonOffset />
                {errorMessage && (
                  <div className="CartError">
                    <InputAlertText noIcon>{errorMessage}</InputAlertText>
                    {errorInterpretation && (
                      <Button className="CartErrorButton blue-link" onClick={onErrorResolveClick}>
                        <FormattedMessage id={`cart.error.action.${errorInterpretation}`} /> &gt;
                      </Button>
                    )}
                  </div>
                )}
                <CartPaymentInfo atCapacity={atCapacity} noStoreError={noStoreError} />
              </div>
            </div>
            <div className="visible-xs DockedCartTotal">
              <CartPriceLine
                className="CartTotal"
                title="Total"
                tax={totalTax}
                price={totalPrice}
                typographyVariant="body1"
              />
            </div>
            <CartButton
              isMobile={isMobile}
              atCapacity={atCapacity}
              noStoreError={noStoreError}
              disabled={isOrderingDisabled}
            />
          </div>
          <LoyaltyFab />
        </Modal.Body>
      </Modal>
    </>
  );
}

Cart.propTypes = {
  gglocation: gglocationShape.isRequired,
  orderItems: PropTypes.arrayOf(orderItemShape).isRequired,
  show: PropTypes.bool.isRequired,
  noStoreError: PropTypes.string,
  errorMessage: PropTypes.string,
  errorInterpretation: PropTypes.string,
  quantity: PropTypes.number,
  capacity: PropTypes.number,
  loggedIn: PropTypes.bool,
  deliveryInfo: deliveryInfoShape,
  isDeliveryOrder: PropTypes.bool.isRequired,
  onCartHide: PropTypes.func.isRequired,
  onClearCartClick: PropTypes.func.isRequired,
  onErrorResolveClick: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  noStoreError: null,
  errorMessage: null,
  errorInterpretation: null,
  quantity: null,
  capacity: null,
  loggedIn: false,
  deliveryInfo: {},
};

export default Cart;
